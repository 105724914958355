import React from 'react'
import { Container } from 'reactstrap'

import Layout from '../components/layouts/layout'
import ServicesCards from '../components/ServicesCards'
import Faq from '../components/Faq'

const IndexPage = (props) => (
  <Layout>
  	<Container className="full-height-container py-5">
     	<h1 className="mt-5 text-center small">Mes offres de services</h1>
     	<div className="small-container my-5">
     		<p className="text-center big">Une question ? Un problème ? Un seul réflexe : la Boite à Outils</p>
     	</div>
     	
     	<ServicesCards />

      <Faq />

     </Container>
     
  </Layout>
)

export default IndexPage
