import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'reactstrap'
import gearing from '../../images/gearing.svg'


export default class Faq extends React.Component {
  render() {
    return (
    	  <StaticQuery
		    query={graphql`
		      	query faqQuery {
		      		allContentfulFaq {
					  	edges {
					    	node {
					      		id
					      		question {
					        		question
					      		}
					      		reponse {
					        		childMarkdownRemark{
					          			html
					        		}
					      		}
					      		ordre
					    	}
					  	}
					} 
		      	}
		    `}
		    render={data => {
		    	const questions = data.allContentfulFaq.edges.sort((a, b) => a.node.ordre - b.node.ordre)
		    	return(
		    		<Row className="faq-wrapper">
		    			<Col md="4" className="text-right">
     						<img className="faq-gearing-image" src={gearing} alt="engrenages" />
     					</Col>
     					<Col md={{size: 7, offset: 1}}>
          					<h2 className="mb-5">Questions fréquentes</h2>
				    		{
				    			questions.map((q, key) => 
				    				<div key={key} className="item mb-5">
						            	<p className="big semibold">{q.node.question.question}</p>
						            	<div className="innerhtml-content" dangerouslySetInnerHTML={{__html: q.node.reponse.childMarkdownRemark.html }}/>
						     		</div>
				    			)

				    		}	  
				    	</Col>   	
					</Row>
		    	)
		    }}
		  />
    );
  }
}



          

